import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box, Center, Divider } from '@chakra-ui/react';
import { VideoCamera } from 'phosphor-react';
import { serialize } from 'tinyduration';
import { Banner, RecommendationWidget } from 'exoclick-react';

import { BaseHeading, BaseScene, BaseSeo } from '@components/base';
import { TapeList, TapeWatch } from '@components/tape';

export default function TapeWatchTemplate({
  data: { tape, relatedTapes, moreRelatedTapes },
  pageContext: { baseStreamUrl },
}) {
  const { protected_file_code, file_code, cover_image_url } = React.useMemo(
    () => tape || {},
    [tape]
  );
  const coverImgSrc = `${process.env.GATSBY_IMAGE_LIBRARY_URL}/tr:w-730,f-jpg/${cover_image_url}.jpg`;
  const streamUrl = `${baseStreamUrl}e/${protected_file_code}?c_poster=${coverImgSrc}`;
  const downloadUrl = `${baseStreamUrl}d/${file_code}`;

  const moreTapes = React.useMemo(() => {
    if (!relatedTapes?.nodes.length && !moreRelatedTapes?.nodes.length) {
      return [];
    }
    // Merge and remove duplicates
    return [...relatedTapes.nodes, ...moreRelatedTapes.nodes]
      .filter((v, i, a) => a.findIndex((v2) => v2.slug === v.slug) === i)
      .slice(0, 6);
  }, [relatedTapes, moreRelatedTapes]);

  return (
    <BaseScene
      as='section'
      paddingInline={{ base: 0, lg: 4 }}
      pt={{ base: 0, lg: '30px' }}
    >
      {protected_file_code && (
        <TapeWatch
          id='article'
          streamUrl={streamUrl}
          downloadUrl={downloadUrl}
          tape={tape}
        />
      )}
      <Center mt={4}>
        <Banner zoneId={4864560} />
      </Center>
      <Box paddingInline={{ base: 4, lg: 0 }}>
        <Box pt={4} pb={4}>
          <BaseHeading as='h2' icon={VideoCamera}>
            More Videos
          </BaseHeading>
          <Divider pt={1} />
        </Box>
        {moreTapes.length && (
          <TapeList
            justifyContent={{ lg: 'space-between', base: 'center' }}
            gridTemplateColumns={{
              lg: 'repeat(3, 292px)',
              '2md': 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
            tapes={moreTapes}
            tapeTitleProps={{ as: 'h3' }}
          />
        )}
      </Box>
      <Center pt={4}>
        <RecommendationWidget zoneId={4856200} />
      </Center>
    </BaseScene>
  );
}

export function Head({ data, pageContext: { baseStreamUrl } }) {
  const { pathname } = useLocation();
  const { site, tape } = data;
  const { title, cover_image_url, duration, tags, created_at } = tape;
  const { siteUrl, siteTitle, description } = site.siteMetadata;

  const embedUrl = `${baseStreamUrl}e/${tape.protected_file_code}`;

  const coverImg = React.useMemo(
    () =>
      `${process.env.GATSBY_IMAGE_LIBRARY_URL}/tr:w-730,f-jpg/${cover_image_url}.jpg`,
    [cover_image_url]
  );

  const schemaGraphs = React.useMemo(() => {
    const watchUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'Article',
        '@id': `${watchUrl}#article`,
        isPartOf: {
          '@id': watchUrl,
        },
        author: {
          '@type': 'Organization',
          name: siteTitle,
          '@id': `${siteUrl}/#organization`,
        },
        headline: title,
        datePublished: created_at,
        dateModified: created_at,
        mainEntityOfPage: {
          '@id': watchUrl,
        },
        wordCount: title.split(/\s+/).length,
        commentCount: 0,
        publisher: {
          '@type': 'Organization',
          name: siteTitle,
          '@id': `${siteUrl}/#organization`,
        },
        image: {
          '@id': `${watchUrl}#primaryimage`,
        },
        thumbnailUrl: coverImg,
        articleSection: [tags.map((tag) => tag.name)],
        inLanguage: 'en-US',
        potentialAction: {
          '@type': 'WatchAction',
          target: `${watchUrl}#watchvideo`,
        },
      },
      {
        '@type': 'WebPage',
        '@id': watchUrl,
        '@url': watchUrl,
        name: `${title} ∘ ${siteTitle}`,
        isPartOf: {
          '@id': siteUrl,
        },
        primaryImageOfPage: {
          '@id': `${watchUrl}#watchvideo`,
        },
        image: {
          '@id': `${watchUrl}#watchvideo`,
        },
        thumbnailUrl: coverImg,
        datePublished: created_at,
        dateModified: created_at,
        breadcrumb: {
          '@id': `${watchUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [watchUrl],
          },
        ],
      },
      {
        '@type': 'ImageObject',
        name: title,
        description: `Watch ${title}. ${description} for Free`,
        '@id': `${watchUrl}#watchvideo`,
        url: coverImg,
        contentUrl: coverImg,
        inLanguage: 'en-US',
      },
      {
        '@type': 'VideoObject',
        name: title,
        duration: serialize({ seconds: duration }),
        embedUrl: embedUrl,
        thumbnailUrl: coverImg,
        uploadDate: created_at,
        description: `Watch ${title}. ${description} for Free`,
        author: {
          '@type': 'Organization',
          name: siteTitle,
          '@id': `${siteUrl}/#organization`,
        },
      },
      {
        '@type': 'Person',
        '@id': `${siteUrl}/#/schema/person/755219b942b84cc877e3bd7c06f96c11`,
        name: siteTitle,
        image: {
          '@type': 'ImageObject',
          inLanguage: 'en-US',
          '@id': `${siteUrl}/#/schema/person/image/`,
          url: `${siteUrl}/media_logo.png`,
          contentUrl: `${siteUrl}/media_logo.png`,
          caption: siteTitle,
        },
        url: `${siteUrl}/newest`,
      },
    ];
  }, [
    siteUrl,
    siteTitle,
    title,
    description,
    coverImg,
    pathname,
    created_at,
    tags,
    duration,
    embedUrl,
  ]);

  return (
    <BaseSeo
      title={title}
      imageUrl={coverImg}
      schemaGraphs={schemaGraphs}
      isPageVideo
    >
      <meta property='article:published_time' content={created_at} />
      <meta property='article:modified_time' content={created_at} />
      <meta property='video:duration' content={duration} />
      {tags.map(({ id, name }) => (
        <meta key={id} property='article:tag' content={name} />
      ))}
    </BaseSeo>
  );
}

export const query = graphql`
  query tapeQuery($slug: String!, $tapeTags: [Int]) {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        description
      }
    }
    tape(slug: { eq: $slug }) {
      slug
      title
      cover_image_url
      protected_file_code
      file_code
      duration
      created_at
      tags {
        id
        name
      }
    }
    relatedTapes: allTape(
      filter: {
        tags: { elemMatch: { id: { in: $tapeTags } } }
        slug: { ne: $slug }
      }
      limit: 6
      sort: { fields: created_at, order: DESC }
    ) {
      nodes {
        slug
        title
        duration
        cover_image_url
        tags {
          id
          name
        }
      }
    }
    moreRelatedTapes: allTape(
      limit: 6
      filter: { slug: { ne: $slug } }
      sort: { fields: created_at, order: DESC }
    ) {
      nodes {
        slug
        title
        duration
        cover_image_url
        tags {
          id
          name
        }
      }
    }
  }
`;
